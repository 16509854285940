body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.header-navbar {
  padding: 0;
}


.logoStyle {
  height:3rem;
  width: 5rem;
}

.siginingin {
  width: 100%;
  min-height: 100px;
  display: flex;
  justify-content: center;
  justify-items: center;
}

.nd-custom-input {
  background: #f27141;
  color: white;
  font-size: x-large;
  border-radius: 11px;
  min-width: 181px;
  box-shadow: 1px 4px 9px #f84a08;
  border-color: white;
  border-width: thick;
}

.disabled {
  pointer-events: none;
    opacity: 0.5;
}

.signin {
    width: 100%;
    min-height: 300px;
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 50px;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0; 
}

